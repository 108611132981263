import { isPresent } from "./value-helpers"

export function getMarkdownRemarkContent(
  content = {
    childMarkdownRemark: {
      html: ``,
    },
  }
) {
  if (isPresent(content?.childMarkdownRemark)) {
    return content.childMarkdownRemark.html
  }
  return ``
}
