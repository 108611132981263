import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import PropTypes from "prop-types"

import Container from "./container"
import Spacer from "./spacer"

const Wrapper = styled.div`
  color: ${(props) => props.textColor};
  background: ${(props) => props.bgColor};

  @media ${(props) => props.theme.smallUp} {
    padding-top: calc(50vh - ${rem(62)});
  }

  @media ${(props) => props.theme.smallDown} {
    padding-top: ${rem(150)};
    padding-left: ${rem(20)};
    padding-right: ${rem(20)};
  }
`

const Heading = styled.h1.attrs({ className: `styled-h1` })`
  text-align: center;
`

const Content = styled.div.attrs({ className: `styled-h4` })`
  @media ${(props) => props.theme.smallUp} {
    padding: 0;
    padding-left: 36%;
  }
`

const Hero = (props) => (
  <Wrapper
    bgColor={props.bgColor}
    textColor={props.textColor}
    data-testid="hero-block"
  >
    <Container>
      <Heading dangerouslySetInnerHTML={{ __html: props.title }} />

      <Spacer top bottom>
        <Content dangerouslySetInnerHTML={{ __html: props.content }} />
      </Spacer>
    </Container>
  </Wrapper>
)

Hero.propTypes = {
  bgColor: PropTypes.any,
  textColor: PropTypes.any,
  title: PropTypes.string,
  content: PropTypes.string,
}

export default Hero
