import React from "react"
import PropTypes from "prop-types"

import { StoreContext } from "../store"

import Meta from "../components/meta"
import Container from "../components/container"
import Hero from "../components/hero-with-content"
import { StaticQuery, graphql } from "gatsby"

import { getMetaKeywords } from "../utils/meta-keywords-from-array"
import { getMarkdownRemarkContent } from "../utils/markdown-remark-content"

class CareersPageContent extends React.Component {
  greenhouseContainer = null
  scrollDone = false
  static contextType = StoreContext

  componentDidMount() {
    this.context.setHeaderTheme(`bright`)
    /* c8 ignore next 17 */
    if (typeof window !== `undefined` && typeof document !== `undefined`) {
      if (!window.Grnhse) {
        const script = document.createElement(`script`)
        script.setAttribute(
          `src`,
          `https://boards.greenhouse.io/embed/job_board/js?for=clicktherapeutics`
        )
        script.setAttribute(`width`, `100%`)
        script.setAttribute(`data-testid`, `Grnhse`)
        script.onload = () => window.Grnhse && window.Grnhse.Iframe.load()
        document.body.appendChild(script)
      } else {
        if (window.Grnhse) {
          window.Grnhse.Iframe.load()
        }
      }
    }
  }

  /* c8 ignore next */
  componentDidUpdate() {
    /* c8 ignore next 13 */
    if (
      !this.scrollDone &&
      typeof window !== `undefined` &&
      window.location.search.includes(`gh_jid`) &&
      this.greenhouseContainer
    ) {
      this.scrollDone = true
      window.scrollTo({
        top: this.greenhouseContainer.offsetTop,
        behavior: `smooth`,
      })
    }
  }

  render() {
    const careers = this.props.data.careers.nodes

    const content = {
      meta: {
        title:
          careers.length > 0
            ? careers[0].metaTitle
            : `Join Our Team - Careers at Click`,
        description:
          careers.length > 0
            ? careers[0].metaDescription
            : `A career at Click is an opportunity to develop cutting-edge digital therapeutics (DTx) where the code is the treatment! Learn more about our open positions.`,
        keywords:
          careers.length > 0
            ? getMetaKeywords(careers[0].metaKeywords)
            : `Click Therapeutics, ClickTx, Cross-functional team, pre-IPO, Tribeca, New York City, healthcare, mHealth, Telehealth`,
      },
      heroBlock: {
        title: careers.length > 0 ? careers[0].heroTitle : `Join Our Team`,
        content:
          careers.length > 0
            ? getMarkdownRemarkContent(careers[0].heroContent)
            : `We are a rapidly growing pre-IPO company in a nascent and fast-growing space. Listed as a 2022 Best Place to Work in NYC by BuiltIn, we live up to this title by creating an exciting and inclusive workspace that advances our mission to develop safe and effective digital therapies for patients in need. Our diverse, cross-functional team of experts works closely to develop cutting-edge products where the code is the treatment. Our headquarters is located in Tribeca, NYC, at the center of one of the fastest-growing digital health communities. In addition, we understand the future of work means it is possible today to work from anywhere. That is why we partnered with Industrious so any Click employee can access a variety of offices, including all locations nationally and dedicated spaces in central Miami, San Francisco, and Boston locations. Together, our team works to improve healthcare by bringing novel, safe, effective, and engaging therapies to patients in need of new treatment options.`,
        /* c8 ignore next 2 */
        bgColor: (props) => props.theme.colorWode,
        textColor: (props) => props.theme.colorWhite,
      },
    }

    return (
      <React.Fragment>
        <Meta {...content.meta} />

        <Hero {...content.heroBlock} />

        <Container
          ref={(n) => (this.greenhouseContainer = n)}
          style={{ minHeight: `100vh ` }}
          data-testid="Container"
        >
          <div id="grnhse_app" />
        </Container>
      </React.Fragment>
    )
  }
}

CareersPageContent.propTypes = {
  data: PropTypes.object,
  theme: PropTypes.any,
}

const CareersPage = (props) => (
  <StaticQuery
    query={graphql`
      query {
        careers: allContentfulCareersPage(
          filter: { navigationUrl: { eq: "/careers" } }
        ) {
          distinct(field: navigationUrl)
          nodes {
            heroTitle
            heroContent {
              childMarkdownRemark {
                html
              }
            }
            metaDescription
            metaKeywords
            metaTitle
            navigationPosition
            navigationTitle
            navigationUrl
          }
        }
      }
    `}
    render={(data) => <CareersPageContent data={data} {...props} />}
  />
)

export default CareersPage
